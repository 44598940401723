import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout/es"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"
import NewsGrid from "../components/news/news-grid"
import previewImage from "../images/espais/principal_finestra.jpg"

const IndexPage = (props) => {
	const heroText = "Bienvenidos";
	const textSectionText = "\"En Cal Pere del Maset queremos que disfruten de una agradable velada. Les ofrecemos un producto de temporada, en un entorno de lujo. El Alt Penedès, Subirats, Sant Pau d'Ordal. Nuestra casa, que esperemos que sientan como la suya. Bienvenidos.\""
	return	(
		  <Layout location={props.location}>
		    <SEO 
		    	title="Página Prinicipal" 
		    	lang="es"
		    	description={textSectionText}
		    	image={previewImage}
		    />
		    <HeroImage 
		    	titleText={heroText}
		    	image="vinyards" 
		    />
		    <TextSection textSectionText={textSectionText} />
 		 		<Subsection>
 			    <Link to={"/es/carta"}>
 			    	<Title>Consulta la carta nueva</Title>
 			    </Link>
 			    <StaticQuery
 			      query={graphql`
 			        # Replace this comment with the GraphQL query above
 			        {
 			          allHomepageImagesEsYaml {
 			            edges {
 			              node {
 			                title
 			                image {
 			                	id
 			                  childImageSharp {
 			                    fluid {
 			                      aspectRatio
 			                      originalImg
 			                      # Use gatsby-image later for better user experience
 			                      ...GatsbyImageSharpFluid
 			        }}}}}}}
 			      `}
 			      render={data => (
 			        <ImageGallery
 			          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
 			          images={data.allHomepageImagesEsYaml.edges.map(({ node }) => ({
 			          	id: node.image.id,
 			            ...node.image.childImageSharp.fluid,
 			            caption: `${node.title}`,
 			          }))}
 			        />
 			      )}
 			    />
 			  </Subsection>

		 		<Subsection>
			    <Link to={"/es/noticies"}>
			    	<Title>Consulta las últimas Noticias</Title>
			    </Link>
			    <NewsGrid numberPreviews="2" />
			  </Subsection>
		  </Layout>
		)
}

const Title = styled.h1`
	text-align: center;
`

const Subsection = styled.div`
	margin-top: 60px;
	a {
	  text-decoration: none;
	  color: #000;
	  font-weight: bold;
	}
`

export default IndexPage
